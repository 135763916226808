import settings from './settings/settings.routes';
import conversation from './conversation/conversation.routes';
import { routes as searchRoutes } from '../../modules/search/search.routes';
import { routes as contactRoutes } from './contacts/routes';
import { routes as notificationRoutes } from './notifications/routes';
import { routes as inboxRoutes } from './inbox/routes';
import { frontendURL } from '../../helper/URLHelper';
import helpcenterRoutes from './helpcenter/helpcenter.routes';
import campaignsRoutes from './campaigns/campaigns.routes';

const IframeWhatsappPage = () => import('dashboard/components/widgets/DashboardApp/WhatsAppIntegrationPage.vue');
const IframeN8nPage = () => import('dashboard/components/widgets/DashboardApp/WorkflowToolPage.vue');
const IframeCrmPage = () => import('dashboard/components/widgets/DashboardApp/SalesToolPage.vue');


import { FEATURE_FLAGS } from 'dashboard/featureFlags';

import AppContainer from './Dashboard.vue';
import Captain from './Captain.vue';
import Suspended from './suspended/Index.vue';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId'),
      component: AppContainer,
      children: [
        {
          path: frontendURL('accounts/:accountId/captain/:page'),
          name: 'captain',
          component: Captain,
          meta: {
            permissions: ['administrator', 'agent'],
            featureFlag: FEATURE_FLAGS.CAPTAIN,
          },
          props: true,
        },

        {
          path: 'IframeWhatsappPage',
          name: 'IframeWhatsappPage',
          component: IframeWhatsappPage,
          meta: {
            permissions: ['administrator', 'agent'],
          },
        },
        {
          path: 'IframeN8nPage',
          name: 'IframeN8nPage',
          component: IframeN8nPage,
          meta: {
            permissions: ['administrator', 'agent'],
          },
        },
        {
          path: 'IframeCrmPage',
          name: 'IframeCrmPage',
          component: IframeCrmPage,
          meta: {
            permissions: ['administrator', 'agent'],
          },
        },

        ...inboxRoutes,
        ...conversation.routes,
        ...settings.routes,
        ...contactRoutes,
        ...searchRoutes,
        ...notificationRoutes,
        ...helpcenterRoutes.routes,
        ...campaignsRoutes.routes,
      ],
    },
    {
      path: frontendURL('accounts/:accountId/suspended'),
      name: 'account_suspended',
      meta: {
        permissions: ['administrator', 'agent', 'custom_role'],
      },
      component: Suspended,
    },
  ],
};
